<template>
  <v-app>
    <v-container class="my-2 mx-auto" fluid>
      <v-card class="pb-10 pt-2">
        <div class="card-header-padding">
          <v-row justify="space-between">
            <v-col cols="12" class="my-0 py-0">
              <!-- <v-btn @click="_printLessons()">kdfjsjdf</v-btn> -->
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Үнэлгээ 2 - Xяналтын самбар

                <span></span>
              </h5>
              <p>
                xxxХагас жилийн үнэлгээ буюу үнэлгээ 2 -ийн дүнгүүдийг энд
                удирдана.
              </p>
            </v-col>
          </v-row>
          <p class="blue--text">
            Нийт бүлгийн тоо:
            <span class="font-weight-bold">{{ numberOfAllGroups }}</span>
          </p>
        </div>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-data-table
          class="mx-6 scoreTables"
          v-if="departments && numberOfAllGroups > 0"
          :items="departments"
          :headers="headerNames"
          hide-default-footer
          hide-default-header
          :items-per-page="-1"
        >
          <template slot="header">
            <tr style="font-size: 13px">
              <th class="text-start font-weight-normal">No</th>
              <th class="text-start font-weight-normal">Анги</th>
              <th class="text-start font-weight-normal">АУ Багш</th>
              <th class="text-start font-weight-normal">Суралцагч</th>
              <th class="text-start font-weight-normal">Ээлжит хичээл</th>
              <th class="text-start font-weight-normal">Заавал</th>
              <th class="text-start font-weight-normal">Сонгон</th>
              <th class="pl-0">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="text-start font-weight-normal"
                    style="border-bottom: 2px solid #dddddd; padding-left: 5px"
                    >Баталгаажсан</v-col
                  >
                  <v-col
                    class="text-start font-weight-normal"
                    cols="6"
                    style="border-right: 2px solid #dddddd; padding-left: 5px"
                    >Хувь</v-col
                  >
                  <v-col
                    class="text-start font-weight-normal"
                    cols="6"
                    style="padding-left: 5px"
                    >Тоо</v-col
                  >
                </v-row>
              </th>
              <th class="text-start font-weight-normal">Хянагдаж байгаа</th>
              <th class="text-start font-weight-normal">Цуцлагдсан</th>
              <th class="text-start font-weight-normal">Бүртгэсэн</th>
              <th class="text-center font-weight-normal" style="width: 6%">
                Дүнгийн xүснэгт
              </th>
              <th class="text-center font-weight-normal" style="width: 5%">
                Ангийн дундаж
              </th>
            </tr>
          </template>
          <template slot="item" slot-scope="props">
            <tr
              style="cursor: pointer !important"
              @click="goToClassLessons(prog)"
              v-for="(prog, index) in props.item.classGroups"
              :key="props.item.id + prog.id + index"
            >
              <td style="width: 2%" @click="_print(prog)" class="px-1">
                {{ prog.index }}
              </td>
              <td class="font-weight-bold blue--text uppercase">
                {{ prog.fullName }}
              </td>
              <td class="px-1">
                <span v-if="prog['TEACHER_NAME']">
                  {{ prog["TEACHER_NAME"] }}</span
                >
              </td>
              <td
                class="text-center"
                v-if="prog.numberOfStudents && prog.numberOfStudents > 0"
              >
                {{ prog.numberOfStudents }}
              </td>
              <td v-else>***</td>
              <td class="text-center">
                {{ prog.mandatoryNumber + prog.electiveNumber }}
              </td>
              <td class="text-center">{{ prog.mandatoryNumber }}</td>
              <td class="text-center">{{ prog.electiveNumber }}</td>
              <td class="pl-0 text-center">
                <v-row no-gutters style="height: 100% !important">
                  <v-col
                    cols="6"
                    style="border-right: 2px solid #dddddd; padding-left: 5px"
                  >
                    <span
                      style="color: green"
                      v-if="_getConfirmationProcent(prog) == 100"
                      color="green"
                      >100%</span
                    >
                    <span v-else-if="_getConfirmationProcent(prog) == 0">
                      0%</span
                    >
                    <span v-else>
                      {{ _getConfirmationProcent(prog).toFixed(1) }}%</span
                    >
                    <!-- {{
                      (
                        ((prog.year1UnelgeeScores
                          ? prog.year1UnelgeeScores.approvedNumber
                          : 0) /
                          prog.totalLessonStudentsNumber) *
                        100
                      ).toFixed(2) > 0
                        ? ((prog.year1UnelgeeScores
                            ? prog.year1UnelgeeScores.approvedNumber
                            : 0) /
                            prog.totalLessonStudentsNumber) *
                            100 ==
                          100
                          ? ((prog.year1UnelgeeScores
                              ? prog.year1UnelgeeScores.approvedNumber
                              : 0) /
                              prog.totalLessonStudentsNumber) *
                            100
                          : (
                              ((prog.year1UnelgeeScores
                                ? prog.year1UnelgeeScores.approvedNumber
                                : 0) /
                                prog.totalLessonStudentsNumber) *
                              100
                            ).toFixed(1)
                        : 0
                    }}% -->
                  </v-col>

                  <v-col cols="6" style="padding-left: 5px; color: green">{{
                    prog.year1UnelgeeScores
                      ? prog.year1UnelgeeScores.approvedNumber
                      : 0
                  }}</v-col>
                </v-row>
              </td>
              <td class="text-center" style="color: #db9943">
                {{
                  prog.year1UnelgeeScores
                    ? prog.year1UnelgeeScores.inProgressNumber
                    : 0
                }}
              </td>
              <td class="text-center" style="color: red">
                {{
                  prog.year1UnelgeeScores
                    ? prog.year1UnelgeeScores.rejectedNumber
                    : 0
                }}
              </td>
              <td class="text-center" style="color: purple">
                {{
                  prog.year1UnelgeeScores
                    ? prog.year1UnelgeeScores.sentNumber
                    : 0
                }}
              </td>
              <td
                class="text-center blue--text"
                @click.stop="
                  forceRerender();
                  selectedClassGroup = prog;
                  dialogClassScoreMatrix = !dialogClassScoreMatrix;
                "
              >
                <small>xараx</small>
              </td>
              <td class="text-center">
                <span v-if="prog.year1UnelgeeAverageScores">{{
                  prog.year1UnelgeeAverageScores.classAverageScore
                }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-progress-linear
          v-else-if="loading == false"
          indeterminate
          color="red"
        ></v-progress-linear>
      </v-card>

      <v-dialog
        v-model="dialogClassScoreMatrix"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card v-if="renderComponent">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialogClassScoreMatrix = false" color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <MyClassUnelgee2
              :zClassGroup="selectedClassGroup"
            ></MyClassUnelgee2>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  components: {
    MyClassUnelgee2: () => import("@/views/Pages/MyClass/MyClassUnelgee2.vue"),
  },
  data() {
    return {
      selectedClassGroup: null,
      dialogClassScoreMatrix: false,
      numberOfAllGroups: null,
      loading: false,
      numberOfStudents: null,
      selectedYear: null,
      school: null,
      departments: null,
      countOfPrograms: 0,
      teachers: null,
      headerNames: [
        {
          text: "No",
        },
        {
          text: "Анги",
        },
      ],
      renderComponent: true,
    };
  },
  watch: {
    dialogClassScoreMatrix(val) {
      console.log(val);
      if (val) {
        // dialogClassScoreMatrix
      }
    },
  },
  methods: {
    async _printt() {
      console.log("readonig...");
      var list = [];
      var doneNumber = 0;
      for (var dep of this.departments) {
        for (var classGroup of dep.classGroups) {
          await this.userData.school.ref
            .collection("lessons-" + this.userData.school.currentYear)
            .where(
              "classGroupRefs",
              "array-contains",
              fb.db.doc(classGroup.ref.path.replace("2023", "2022"))
            )
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                let lesson = doc.data();
                lesson.ref = doc.ref;
                lesson.id = doc.id;

                lesson.isLesson = true;
                list.push(lesson);

                await lesson.ref
                  .collection("executions")
                  .where(
                    "selectedLesson.classGroupRefs",
                    "==",
                    lesson.classGroupRefs
                  )
                  .get()
                  .then((docs) => {
                    console.log(docs.size);
                    docs.forEach((doc) => {
                      let exe = doc.data();
                      exe.ref = doc.ref;
                      exe.id = doc.id;

                      list.push(exe);
                    });
                  });
              });
            });
        }
      }
      console.log(list.length);

      for (let i = 0; i < list.length; i += 499) {
        const chunk = list.slice(i, i + 499);
        var batch = fb.db.batch();

        for (var doc of chunk) {
          if (doc.isLesson) {
            console.log(doc);
            if (doc.classGroupRefs) {
              var xlist = [];
              for (let x of doc.classGroupRefs) {
                xlist.push(fb.db.doc(x.path.replace("2022", "2023")));
              }
              doc.classGroupRefs = xlist;
            }

            var ylist = [];
            for (let x of doc.classGroups) {
              if (x.classGroupRef)
                x.classGroupRef = fb.db.doc(
                  x.classGroupRef.path.replace("2022", "2023")
                );
              if (x.departmentRef)
                x.departmentRef = fb.db.doc(
                  x.departmentRef.path.replace("2022", "2023")
                );
              if (x.programData && x.programData.ref)
                x.programData.ref = fb.db.doc(
                  x.programData.ref.path.replace("2022", "2023")
                );

              ylist.push(x);
            }
            doc.classGroups = ylist;
            if (doc.programRef)
              doc.programRef = fb.db.doc(
                doc.programRef.path.replace("2022", "2023")
              );

            batch.update(doc.ref, {
              classGroupRefs: doc.classGroupRefs,
              classGroups: doc.classGroups,
              programRef: doc.programRef,
            });
          } else {
            if (doc.classGroupRef)
              doc.classGroupRef = fb.db.doc(
                doc.classGroupRef.path.replace("2022", "2023")
              );
            else doc.classGroupRef = null;

            if (doc.departmentRef)
              doc.departmentRef = fb.db.doc(
                doc.departmentRef.path.replace("2022", "2023")
              );
            else doc.departmentRef = null;

            if (doc.selectedClassGroup && doc.selectedClassGroup.classGroupRef)
              doc.selectedClassGroup.classGroupRef = fb.db.doc(
                doc.selectedClassGroup.classGroupRef.path.replace(
                  "2022",
                  "2023"
                )
              );
            else doc.selectedClassGroup = null;
            if (doc.selectedClassGroup && doc.selectedClassGroup.departmentRef)
              doc.selectedClassGroup.departmentRef = fb.db.doc(
                doc.selectedClassGroup.departmentRef.path.replace(
                  "2022",
                  "2023"
                )
              );
            else doc.selectedClassGroup = null;

            if (doc.selectedLesson) {
              if (doc.selectedLesson.classGroupRefs) {
                var xlist2 = [];
                for (let x of doc.selectedLesson.classGroupRefs) {
                  xlist2.push(fb.db.doc(x.path.replace("2022", "2023")));
                }
                doc.selectedLesson.classGroupRefs = xlist2;
              }

              var ylist2 = [];
              for (let x of doc.selectedLesson.classGroups) {
                if (x.classGroupRef)
                  x.classGroupRef = fb.db.doc(
                    x.classGroupRef.path.replace("2022", "2023")
                  );
                if (x.departmentRef)
                  x.departmentRef = fb.db.doc(
                    x.departmentRef.path.replace("2022", "2023")
                  );

                ylist2.push(x);
              }
              doc.selectedLesson.classGroups = ylist2;
              if (doc.selectedLesson.programRef)
                doc.selectedLesson.programRef = fb.db.doc(
                  doc.selectedLesson.programRef.path.replace("2022", "2023")
                );
            } else {
              doc.selectedLesson = null;
            }

            if (doc.statuses) {
              var zlist = [];
              for (let x of doc.statuses) {
                if (x.docRef) {
                  x.docRef = fb.db.doc(x.docRef.path.replace("2022", "2023"));
                }
                zlist.push(x);
              }
              doc.statuses = zlist;
            } else {
              doc.statuses = null;
            }

            batch.update(doc.ref, {
              classGroupRef: doc.classGroupRef,
              departmentRef: doc.departmentRef,
              selectedClassGroup: doc.selectedClassGroup,
              selectedLesson: doc.selectedLesson,
              statuses: doc.statuses,
            });
          }
        }

        batch.commit().then(() => {
          doneNumber = doneNumber + chunk.length;
          console.log(doneNumber, list.length);
        });
      }
    },
    _getConfirmationProcent(item) {
      var xxx = item.year1UnelgeeScores
        ? item.year1UnelgeeScores.approvedNumber
        : 0;
      if (item.numberOfStudents > 0 && item.totalLessonStudentsNumber)
        return (xxx / item.totalLessonStudentsNumber) * 100;
      return 0;
    },
    async forceRerender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.renderComponent = true;
    },
    goToClassLessons(prog) {
      this.$router.push({
        name: "Unelgee2Confirmations",
        params: { zClassGroupRefPath: prog.ref.path },
      });
    },
    getTotalLessonStudentsNumber(classGroup) {
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroup.ref)
        .get()
        .then((docs) => {
          var totalLessonStudentsNumber = 0;
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (lesson.byTeachers && lesson.byTeachers.length) {
              if (lesson.numberOfStudents) {
                totalLessonStudentsNumber =
                  totalLessonStudentsNumber + lesson.numberOfStudents;
              }
            }
          });
          if (
            classGroup.totalLessonStudentsNumber == undefined ||
            classGroup.totalLessonStudentsNumber != totalLessonStudentsNumber
          ) {
            classGroup.ref
              .update({
                totalLessonStudentsNumber: totalLessonStudentsNumber,
              })
              .then(() => {
                classGroup.totalLessonStudentsNumber =
                  totalLessonStudentsNumber;
                // console.log("total student number updated");
                this.$forceUpdate();
              });
          }
        });
    },
    getClassLessonNumber(classGroup) {
      var mandatoryNumber = 0;
      var electiveNumber = 0;
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroup.ref)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (lesson.byTeachers && lesson.byTeachers.length) {
              if (lesson.courseInfo.ENROLLMENT_CATEGORY == "MANDATORY") {
                mandatoryNumber++;
              } else if (lesson.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
                electiveNumber++;
              }
            }
          });
          var batch = fb.db.batch();
          var doBatch = false;
          if (
            classGroup.electiveNumber == undefined ||
            classGroup.electiveNumber != electiveNumber
          ) {
            batch.update(classGroup.ref, {
              electiveNumber: electiveNumber,
            });
            doBatch = true;
          }
          if (
            classGroup.mandatoryNumber == undefined ||
            classGroup.mandatoryNumber != mandatoryNumber
          ) {
            batch.update(classGroup.ref, {
              mandatoryNumber: mandatoryNumber,
            });
            doBatch = true;
          }
          if (doBatch) {
            batch.commit().then(() => {
              classGroup.electiveNumber = electiveNumber;
              classGroup.mandatoryNumber = mandatoryNumber;
              // console.log("lesson number updated");
              this.$forceUpdate();
            });
          }
        });
    },
    getClassScoreAnalytics(classGroup) {
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("classGroupRefs", "array-contains", classGroup.ref)
        .get()
        .then((docs) => {
          var approvedNumber = 0;
          var inProgressNumber = 0;
          var rejectedNumber = 0;
          var sentNumber = 0;
          console.log(docs.size);
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (lesson.byTeachers && lesson.byTeachers.length > 0) {
              lesson.byTeachers.forEach((teacher) => {
                if (lesson["year1UnelgeeScores-" + teacher.teacherId]) {
                  if (
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .approvedNumber &&
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .approvedNumber != "NaN"
                  ) {
                    approvedNumber =
                      approvedNumber +
                      lesson["year1UnelgeeScores-" + teacher.teacherId]
                        .approvedNumber;
                  }
                  if (
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .inProgressNumber &&
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .inProgressNumber != "NaN"
                  ) {
                    inProgressNumber =
                      inProgressNumber +
                      lesson["year1UnelgeeScores-" + teacher.teacherId]
                        .inProgressNumber;
                  }
                  if (
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .rejectedNumber &&
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .rejectedNumber != "NaN"
                  ) {
                    rejectedNumber =
                      rejectedNumber +
                      lesson["year1UnelgeeScores-" + teacher.teacherId]
                        .rejectedNumber;
                  }
                  if (
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .sentNumber &&
                    lesson["year1UnelgeeScores-" + teacher.teacherId]
                      .sentNumber != "NaN"
                  ) {
                    sentNumber =
                      sentNumber +
                      lesson["year1UnelgeeScores-" + teacher.teacherId]
                        .sentNumber;
                  }
                }
              });
            }
          });
          var year1UnelgeeScores = {
            approvedNumber: approvedNumber,
            inProgressNumber: inProgressNumber,
            rejectedNumber: rejectedNumber,
            sentNumber: sentNumber,
          };

          if (
            !classGroup.year1UnelgeeScores ||
            classGroup.year1UnelgeeScores.approvedNumber !=
              year1UnelgeeScores.approvedNumber ||
            classGroup.year1UnelgeeScores.inProgressNumber !=
              year1UnelgeeScores.inProgressNumber ||
            classGroup.year1UnelgeeScores.rejectedNumber !=
              year1UnelgeeScores.rejectedNumber ||
            classGroup.year1UnelgeeScores.sentNumber !=
              year1UnelgeeScores.sentNumber
          ) {
            classGroup.ref
              .update({
                year1UnelgeeScores: year1UnelgeeScores,
              })
              .then(() => {
                classGroup.year1UnelgeeScores = year1UnelgeeScores;
                console.log("analytics updated", classGroup.ref.path);
                this.$forceUpdate();
              });
          }
        });
    },
    _print(prog) {
      console.log(prog.ref.path);
    },
    async _setupp() {
      if (this.userData.school) {
        this.school = this.userData.school;
        this.selectedYear = this.school.currentYear;
        this._getDepartments(this.userData.school.currentYear);
      } else {
        this.school = null;
        this.selectedYear = null;
      }
    },
    _getDepartments(startYear) {
      this.numberOfAllGroups = 0;
      this.userData.school.ref
        .collection("departments-" + startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          this.departments = [];
          this.classGroups = [];
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .where("deleted", "==", false)
              .orderBy("name", "asc")
              .get()
              .then((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  if (prog.fullName == undefined) {
                    prog.ref.update({ fullName: dep.name + prog.name });
                  }

                  if (
                    prog.numberOfStudents != undefined &&
                    prog.numberOfStudents != null &&
                    prog.numberOfStudents > 0
                  ) {
                    dep.numberOfStudents =
                      dep.numberOfStudents + prog.numberOfStudents;
                  } else {
                    await this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .where(
                        "classGroup-" + this.userData.school.currentYear,
                        "==",
                        prog.ref
                      )
                      .get()
                      .then((docs) => {
                        console.log(docs.size, "aaa");
                        prog.numberOfStudents = 0;
                        docs.forEach((doc) => {
                          if (doc.data().moved != true) {
                            prog.numberOfStudents++;
                          }
                        });
                        dep.numberOfStudents =
                          dep.numberOfStudents + prog.numberOfStudents;
                        prog.ref.update({
                          numberOfStudents: prog.numberOfStudents,
                        });
                      });
                  }

                  prog.index = ++this.numberOfAllGroups;
                  prog.loading = false;
                  this.getTotalLessonStudentsNumber(prog);
                  this.getClassScoreAnalytics(prog);
                  this.getClassLessonNumber(prog);
                  dep.classGroups.push(prog);
                });
              });
            this.departments.push(dep);
          });
        });
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    countStudents: function () {
      var count = 0;
      if (this.departments) {
        this.departments.forEach((dep) => {
          count = count + dep.numberOfStudents;
        });
      }

      return count;
    },
  },
  created() {
    if (this.userData.school) this._setupp();
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
  padding-left: 5px !important;
}
</style>
